<template>
  <div>
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text">
          العروض
        </h1>
        <v-row class="mt-5">
          <v-col
            md="3"
            cols="12"
          >
            <v-btn
              tile
              color="primary"
              @click="addDailog.open = true"
            >
              اضافة عرض
              <v-icon
                right
                style="font-size: 15px"
              >
                fa-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            md="4"
            cols="12"
          >
            <div class="d-flex flex-row">
              <v-text-field
                v-model="table.search"
                label="البحث"
                outlined
                dense
                @keyup.enter="search()"
              ></v-text-field>
              <button
                class="search-btn"
                @click="search()"
              >
                <v-icon style="font-size: 18px; color: white">
                  fa-search
                </v-icon>
              </button>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="table.headers"
              loading-text="جاري التحميل ... الرجاء الانتظار"
              :items="table.teacherData"
              :options.sync="tableOptions"
              :server-items-length="table.totalTeacherData"
              :loading="table.loading"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100],
              }"
            >
              <template
                slot="item.offers_id"
                slot-scope="props"
              >
                {{ ((tableOptions.page - 1) * tableOptions.itemsPerPage + props.index) + 1 }}
              </template>
              <template v-slot:item.offers_img="{ item }">
                <img
                  v-if="item.offers_img"
                  class="teacher_image_table"
                  :src="$store.state.FileUrl + item.offers_img"
                  alt
                  width="50"
                  height="50"
                  @click="showImage(item.offers_img)"
                />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="edit(item)"
                    >
                      fa-edit
                    </v-icon>
                  </template>
                  <span>تعديل</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#FF5252"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="deleteItem(item)"
                    >
                      fa-trash
                    </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- image Teacher dialog -->
    <v-dialog
      v-model="table.showImageDailog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row class="justify-center">
                <img
                  :src="this.$store.state.FileUrl + table.imageUrlForShow"
                  alt
                  width="300"
                  height="200"
                />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="table.showImageDailog = false"
          >
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->

    <!-- addDialog -->
    <v-dialog
      v-model="addDailog.open"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDailog.isFormValid">
              <v-row>
                <!-- offers_name -->
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="addData.data.offers_name"
                    label="الاسم"
                    outlined
                    :rules="Rules.name"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <!-- offers_price -->
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="addData.data.offers_price"
                    label="السعر"
                    outlined
                    :rules="Rules.price"
                    dense
                    required
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
                <!-- offers_end_date -->
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-menu
                    v-model="menuAccountOffersEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.data.offers_end_date"
                        :rules="Rules.end_date"
                        dense
                        label="تاريخ نهاية العرض"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addData.data.offers_end_date"
                      :min="currentDate"
                      @input="menuAccountOffersEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- offers_img -->
                <v-col
                  cols="12"
                >
                  <div class="d-flex justify-space-around align-center">
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.img_addUpload.click()"
                    >
                      اضافة الصورة
                    </v-btn>
                    <input
                      v-show="false"
                      id="img_add"
                      ref="img_addUpload"
                      type="file"
                      @change="changeAddImg($event)"
                    />
                    <div
                      class="image-container"
                    >
                      <img
                        v-show="addData.IsImgShowing"
                        :src="addData.selectedImg"
                        :lazy-src="addData.imgUrl"
                        alt
                        width="60"
                        height="60"
                      />
                    </div>
                  </div>
                </v-col>

                <!-- offers_note -->
                <v-col cols="12">
                  <v-textarea
                    v-model="addData.data.offers_note"
                    name="input-7-1"
                    label="النبذة"
                    append-icon="fa-scroll"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="addDailog.open = false"
          >
            الغاء
          </v-btn>
          <v-btn
            color="primary"
            :loading="addDailog.loading"
            :disabled="!addDailog.isFormValid"
            @click="submitAdd"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- addDialog -->

    <!-- edit dialog -->
    <v-dialog
      v-model="editDailog.open"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="editDailog.isFormValid">
              <v-row>
                <!-- offers_name -->
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.offers_name"
                    label="الاسم"
                    :rules="Rules.name"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>

                <!-- offers_price -->
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.offers_price"
                    label="السعر"
                    outlined
                    :rules="Rules.price"
                    dense
                    required
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- offers_end_date -->
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-menu
                    v-model="menuAccountOffersEndDateEdit"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.offers_end_date"
                        :rules="Rules.end_date"
                        dense
                        label="تاريخ نهاية العرض"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.offers_end_date"
                      :min="currentDate"
                      @input="menuAccountOffersEndDateEdit = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Logo -->
                <v-col
                  cols="12"
                >
                  <div class="d-flex justify-space-around align-center">
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.img_editUpload.click()"
                    >
                      تعديل الصورة
                    </v-btn>

                    <input
                      v-show="false"
                      id="img_edit"
                      ref="img_editUpload"
                      type="file"
                      @change="changeEditImg($event)"
                    />
                    <div
                      class="image-container"
                    >
                      <img
                        v-show="editData.IsImgShowing"
                        :src="editData.imgUrl"
                        :lazy-src="editData.imgUrl"
                        alt
                        width="60"
                        height="60"
                      />
                    </div>
                  </div>
                </v-col>

                <!-- offers_note -->
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.offers_note"
                    name="input-7-1"
                    label="النبذة"
                    append-icon="fa-scroll"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="editDailog.open = false"
          >
            الغاء
          </v-btn>
          <v-btn
            color="primary"
            :loading="editDailog.loading"
            :disabled="!editDailog.isFormValid"
            @click="submitEdit"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->

    <!-- delete dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="headline justify-center"
        >
          هل انت متأكد من حذف هذا العرض ؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            الغاء
          </v-btn>
          <v-btn
            color="primary white--text"
            :loading="deleteItemLoading"
            @click="deleteItemConfirm"
          >
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->

    <!--- Dailog for show info to user-->
    <v-dialog
      v-model="dialogData.open"
      max-width="500px"
    >
      <v-toolbar
        :color="dialogData.color"
        dense
      ></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center">
          {{
            dialogData.bodyText
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1 justify-start"
            text
            @click="dialogData.open = false"
          >
            تم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },
      currentDate: this.getCurrentDate(),

      menuAccountOffersEndDate: false,

      menuAccountOffersEndDateEdit: false,

      addDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },

      deleteItemLoading: false,

      editedItem: {},

      Rules: {
        name: [value => !!value || 'الاسم مطلوب'],
        price: [value => !!value || 'السعر مطلوب'],
        end_date: [value => !!value || 'تاريخ نهاية العرض مطلوب'],
      },

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      addData: {
        IsImgShowing: false,
        selectedImg: null,
        data: {
          offers_name: null,
          offers_note: null,
          offers_end_date: null,
          offers_price: null,
        },
      },

      editData: {
        IsImgShowing: false,
        imgUrl: null,
        selectedImg: null,
      },

      deletedItem: {},

      tableOptions: {},

      table: {
        search: null,
        totalTeacherData: 0,
        teacherData: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'offers_id',
          },
          {
            text: 'الاسم',
            sortable: false,
            value: 'offers_name',
          },
          {
            text: 'السعر',
            sortable: false,
            value: 'offers_price',
          },
          {
            text: 'تاريخ نهاية العرض',
            sortable: false,
            value: 'offers_end_date',
          },
          {
            text: 'الايام المتبقية',
            sortable: false,
            value: 'days',
          },
          { text: 'الصورة', sortable: false, value: 'offers_img' },
          {
            text: 'النبذة',
            sortable: false,
            value: 'offers_note',
          },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },

    'editData.selectedImg': {
      handler() {
        if (this.editData.imgUrl === null) {
          this.editData.imgUrl = this.$store.state.FileUrl + this.editedItem.offers_img
          this.editData.selectedImg = null
        } else {
          this.editData.imgUrl = this.editData.selectedImg
        }
      },
    },
  },

  // async mounted() {
  //   this.getDataAxios()
  // },
  methods: {
    getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .post('/offers/getList', {
          page,
          limit: itemsPerPage,
          search,
        })
        .then(Response => {
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.table.loading = false
            this.table.teacherData = Response.data.results
            this.table.totalTeacherData = Response.data.count
          }
        })
        .catch(error => {
          this.table.loading = false
          console.log('error', error)
        })
    },

    showImage(image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },

    changeAddImg(event) {
      this.addData.IsImgShowing = true

      const file = event.target.files[0]
      this.addData.selectedImg = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.addData.selectedImg = URL.createObjectURL(file)
        } else {
          console.log('error_image_add')
        }
      } else {
        this.addData.selectedImg = null
      }
    },

    changeEditImg(event) {
      this.editData.IsImgShowing = true

      const file = event.target.files[0]
      this.editData.selectedImg = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.editedItem.offers_img = null
          this.editData.selectedImg = URL.createObjectURL(file)
        } else {
          console.log('error_image_edit')
        }
      } else {
        this.editData.selectedImg = null
      }
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteItemLoading = true
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .delete(`offers/${this.deletedItem.offers_id}`)
        .then(Response => {
          if (Response.data.error === false) {
            this.deleteItemLoading = false
            this.dialogDelete = false
            this.getDataAxios()
            this.showDialogfunction(Response.data.results, 'primary')
          } else {
            this.showDialogfunction(Response.data.results, '#FF5252')
          }
        })
        .catch(error => {
          this.deleteItemLoading = false
          this.dialogDelete = false
          this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
          console.log('error', error)
        })
    },

    submitAdd() {
      this.addDailog.loading = true
      const imageSelected = document.querySelector('#img_add')

      const formData = new FormData()

      if (imageSelected.value === '') {
        this.addDailog.loading = false

        // if there is no logo and no selected logo
        this.showDialogfunction('يجب اختيار صورة', '#FF8A80')
      } else {
        this.formAppendAdd(formData, imageSelected.files[0])

        this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

        this.axios
          .post('offers', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(Response => {
            this.addDailog.loading = false
            if (Response.data.error === false) {
              this.addDailog.open = false
              this.addData.data.offers_name = null
              this.addData.data.offers_price = null
              this.addData.data.offers_end_date = null
              this.addData.data.offers_note = null
              imageSelected.value = ''
              this.addData.IsImgShowing = false
              this.showDialogfunction(Response.data.results, 'primary')
              this.getDataAxios()
            } else {
              this.showDialogfunction(Response.data.results, '#FF8A80ed')
            }
          })
          .catch(error => {
            this.addDailog.loading = false
            this.showDialogfunction('حدث خطأ يرجى المحاولة مجددا', '#FF8A80')
            console.log('error', error)
          })
      }
    },

    edit(item) {
      this.editedItem = { ...item }
      if (!this.editedItem.offers_img) {
        this.editData.IsImgShowing = false
      } else {
        this.editData.IsImgShowing = true
        this.editData.imgUrl = this.$store.state.FileUrl + this.editedItem.offers_img
      }
      this.editDailog.open = true
    },

    submitEdit() {
      this.editDailog.loading = true
      const imageSelected = document.querySelector('#img_edit')

      const formData = new FormData()

      if (imageSelected.value === '' && this.editedItem.offers_img == null) {
        this.editDailog.loading = false

        // if there is no logo and no selected logo
        this.editDailog.loading = false
        this.showDialogfunction('يجب اختيار صورة', '#FF8A80')
      } else {
        const image = this.editedItem.offers_img ? this.editedItem.offers_img : imageSelected.files[0]

        this.formAppend(formData, image)

        this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

        this.axios
          .put('offers', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(Response => {
            this.editDailog.loading = false
            if (Response.data.error === false) {
              this.showDialogfunction(Response.data.results, 'primary')
              this.getDataAxios()
            } else {
              this.showDialogfunction(Response.data.results, '#FF8A80ed')
            }
          })
          .catch(error => {
            this.addBtnLoading = false
            this.showDialogfunction('حدث خطأ يرجى المحاولة مجددا', '#FF8A80')
            console.log('error', error)
          })
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    formAppend(formData, image) {
      formData.append('offers_img', image)
      formData.append('offers_name', this.editedItem.offers_name)
      formData.append('offers_price', this.editedItem.offers_price)
      formData.append('offers_end_date', this.editedItem.offers_end_date)
      formData.append('offers_note', this.editedItem.offers_note)
      formData.append('offers_id', this.editedItem.offers_id)
    },

    formAppendAdd(formData, image) {
      formData.append('offers_img', image)
      formData.append('offers_name', this.addData.data.offers_name)
      formData.append('offers_price', this.addData.data.offers_price)
      formData.append('offers_end_date', this.addData.data.offers_end_date)
      formData.append('offers_note', this.addData.data.offers_note)
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    getCurrentDate() {
      const d = new Date()
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) {
        month = `0${month}`
      }
      if (day.length < 2) {
        day = `0${day}`
      }

      return [year, month, day].join('-')
    },
  },
}
</script>

<style scoped>
.teacher_image_table {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}
table.v-table tbody td {
  color: black;
}
</style>
